import LoginForm from '../components/auth/LoginForm';

import logo_mini from '../assets/logoOliverHead_greffier.png';
import logoInfogreffe_mini from '../assets/oliverLogoInfogreffe_mini.png';

// import logo from '../assets/oliverLogo.png';
import logo from '../assets/logoOliver_greffier.png';
import logoInfogreffe from '../assets/oliverLogoInfogreffe.png';

import openAI_logo from "../assets/openai_logo.png"
import infogreffe_logo from "../assets/infogreffe_logo-removebg-preview.png"

import { useState } from "react";
import Modal from "../components/Modal";
import Cgus from "../components/auth/Cgus";
import { useColorsContext } from "../context/GlobalContext";

const Login = () => {

  const { subdomain } = useColorsContext();

  const [modalCguOpen, setModalCguOpen] = useState("");
  const [modalTile, setModalTitle] = useState('');


  return (
    <>
      <div className="loginPage w-[100%] h-[100dvh]">

        <div className="loginInfosContainer ">
          <div className={`loginWords bg-[#8EBAEA]`}>
            {/* {
              subdomain == "infogreffe" ?
                <img className={"loginWordsLogo"} src={logoInfogreffe_mini} alt="logo mini" />
                :
                <img className={"loginWordsLogo"} src={logo_mini} alt="logo mini" />
            } */}

            <img className={"loginWordsLogo"} src={logo_mini} alt="logo mini" />
            {/* {subdomain == "infogreffe" ? */}
            {subdomain == "oliver" ?
              <>
                <p className="loginWordsW1">une réponse instantanée...*</p>
                <p className="loginWordsW2">...sur une question administrative</p>
                <p className="loginWordsW3">...ou un sujet juridique</p>
              </> :
              <>
                <p className="loginWordsW1">Instantané</p>
                <p className="loginWordsW2">Disponible 24/7</p>
                <p className="loginWordsW3">Apprenant</p>
              </>
            }
            <p className="text-[0.25em] leading-3	 absolute bottom-1">* la réponse étant basée sur un moteur d'intelligence artificielle, une fiabilté à 100% n'est pas garantie. De plus, ce legalbot ne fournit pas de conseil juridique, réservé aux avocats.</p>
          </div>

          <div className={`loginInfos overflow-y-scroll`}
          >
            <p>Oliver.legal est un agent conversationnel spécialisé dans le juridique.<br />
              Son nom s'inspire d'Oliver Wendell Holmes, considéré comme le plus fameux juriste américain.</p>
            <p>Oliver.legal est basé sur la dernière technologie d'intelligence artificielle générative (genAI) et possède pour base de connaissance
              tout le corpus légal et réglementaire francais (+200 000 articles et +2000 fiches techniques), ce qui
              lui permet de répondre à un large éventail de questions juridiques et administratives.</p>
            <div>
              <p>Sa particularité réside notamment dans :</p>
              <ul>
                <li>• la possibilite de contextualiser la question avec les données de son entreprise,</li>
                <li>• l'étendue de sa base de connaissance qui ne cesse d'être mise à jour et enrichie,</li>
                <li>• sa capacité à citer de manière fiable les sources qu'il utilise dans ses réponses.</li>
                <li>• la possibilité d'integrer une base de connaissance spécifique ou interne à une organisation.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="loginContainer overflow-y-scroll">

          {subdomain == "oliver" ?
            <img className={"loginContainerLogo"} src={logoInfogreffe} alt="logo mini" /> :
            <img className={"loginContainerLogo"} src={logo} alt="logo mini" />
          }

          <div className="loginContainerConnexion">
            <p>Découvrez Oliver,<br />
              l'assistant juridique boosté à l'IA
            </p>
            <div className='w-[90%]'>
              <LoginForm setCgus={setModalTitle} setModalCguOpen={setModalCguOpen} />
            </div>
          </div>

          <div>
            <div className={`${subdomain !== "infogreffe" && "hidden"}`}>
              <img className={"w-24"} src={infogreffe_logo} alt="logo mini" />

            </div>
            {/* <div >
              <img className={"w-24"} src={openAI_logo} alt="logo mini" />


            </div> */}
          </div>
          <div className="w-[100%] flex justify-end">

            <div className={`loginContainerFooter bg-[#F6F9FD]`}>
              <p><a onClick={() => { setModalTitle("Mentions légales"); setModalCguOpen(true) }} className="cursor-pointer text-[#06094f] font-semibold">Mentions légales</a></p>
              <p><a onClick={() => { setModalTitle("Politique de confidentialité"); setModalCguOpen(true) }} className="cursor-pointer text-[#06094f] font-semibold">Politique de confidentialité</a></p>
            </div>
            {/* <p><a onClick={() => {setModalTitle("Politiques de GGU"); setModalCguOpen(true)}} className="cursor-pointer">Politiques de GGU</a></p> */}
          </div>
        </div>
      </div>
      <Modal title={modalTile} modalOpen={modalCguOpen} setModalOpen={setModalCguOpen} modalSize={"cgus"}>
        <Cgus content={modalTile}></Cgus>
      </Modal>
    </>
  );
};
export default Login;
