import React from "react";
import LoginFormInfogreffe from "./LoginFormInfogreffe";
import logo from "../../assets/oliverLogo.png";

import logoMygreffe from "../../assets/logoOliverHead_greffier.png";
import logoInfogreffe from "../../assets/logoOliver_vanillaDark3-removebg-preview.png";
import Cgus from "./Cgus";
import { useState } from "react";
import { useAppContext } from "../../context/AppContext";

const Login = ({ message = null, setModalLoginOpen }) => {
  const { subdomain, subDomains } = useAppContext();
  const [action, setAction] = useState("");
  const closeModal = () => {
    setModalLoginOpen(false);
  };
  const [cgus, setCgus] = useState("");
  return (
    <div className="h-full">
      <div className={`flex flex-col items-center justify-center space-y-5 mt-2`}>
        <img className={"w-52 pt-8 pb-4"} src={`${logoMygreffe}`} alt="logo mini" style={{ 'background-color': 'inherit' }} />
        {message === "need_to_be_connected" ? (
          <p className="text-center font-bold text-gray-400">
            <>
              Pour tester la version avancée d'Oliver <br /> vous devez être
              connecté.
            </>
          </p>
        ) : message === "need_to_be_connected_for_reservation" ? (
          <>
            <p className="text-center font-bold text-[#06094F]">
              Pour réserver une formalité, vous devez être connecté(e).
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" className={"w-40"} version="1.1" viewBox="-5.0 -5.0 110.0 110.0">
              <path fill="#06094F" d="m95.969 8.4688-10.312 10.281 1.8516 1.8203c4.6562 4.6875 4.6562 12.254 0 16.938l-10.164 10.168 1.9375 1.9375c1.2266 1.2148 1.2305 3.1953 0.015625 4.4219-1.2148 1.2227-3.1953 1.2305-4.4219 0.015625l-4.1562-4.1875-20.582-20.582-4.1875-4.1562c-0.58203-0.58203-0.91406-1.375-0.91406-2.2031s0.33203-1.6211 0.91406-2.2031c1.2305-1.2188 3.2109-1.2188 4.4375 0l1.9375 1.9375 10.164-10.164h0.003907c4.6836-4.6562 12.25-4.6562 16.938 0l1.8203 1.8516 10.281-10.312c1.2266-1.2188 3.207-1.2109 4.4297 0.007812 1.2188 1.2227 1.2266 3.2031 0.007812 4.4297zm-42.055 45.445-10.426 10.43-7.832-7.832 10.43-10.43v0.003907c1.2148-1.2188 1.2148-3.1914 0-4.4062-1.2188-1.2188-3.1914-1.2188-4.4062 0l-10.43 10.395-1.9688-1.9375-4.1562-4.1875c-0.58203-0.58203-1.375-0.91406-2.2031-0.91406s-1.6211 0.33203-2.2031 0.91406c-1.2188 1.2305-1.2188 3.2109 0 4.4375l1.9375 1.9375-10.164 10.168c-4.6562 4.6836-4.6562 12.25 0 16.938l1.8516 1.8203-10.312 10.281c-1.2188 1.2266-1.2188 3.2109 0 4.4375 1.2305 1.207 3.207 1.207 4.4375 0l10.281-10.312 1.8203 1.8516c4.6836 4.668 12.258 4.668 16.938 0l10.168-10.164 1.9375 1.9375c0.58203 0.59375 1.3789 0.93359 2.2109 0.94141 0.83203 0.003906 1.6328-0.32031 2.2266-0.91016 0.58984-0.58594 0.92188-1.3867 0.92188-2.2188s-0.33203-1.6328-0.92188-2.2188l-4.1875-4.1562-1.9375-1.9688 10.398-10.43h-0.003907c1.2188-1.2148 1.2188-3.1875 0-4.4062-1.2148-1.2148-3.1875-1.2148-4.4062 0z" />
            </svg>
          </>
        ) : (
          <>
            {action === "" &&
              (
                <p className="p-0 text-center font-bold text-gray-400 ">
                  <>Pour continuer, veuillez vous connecter ou vous inscrire.</>
                </p>
              )}
          </>
        )}
        <div className={`pb-8 px-2 w-full ${cgus == "" && "hidden"}`}>
          <div className="md:px-8">
            <Cgus setCgus={setCgus} content={cgus} signup={true} />
          </div>
        </div>
        <div className={`pb-8 px-2 w-full ${cgus != "" && "hidden"}`}>
          <div className="md:px-8">
            <LoginFormInfogreffe
              setCgus={setCgus}
              closeModal={closeModal}
              action={action}
              setAction={setAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
